<template>
  <div class="selfInterfer">
    <div class="container">
      <Practice v-if="isPractice"></Practice>
      <div v-else style="height: 40px"></div>
      <div class="main" :style="{ 'margin-top': isPractice ? '55px' : '20px' }">
        <div class="line"></div>
        <img
          v-if="showStatus && original[current].correct_answer == 'Q'"
          :class="original[current].type == 1 ? 'left' : 'right'"
          src="../../assets/image/self/left.png"
          alt=""
        />
        <img
          v-if="showStatus && original[current].correct_answer == 'P'"
          :class="original[current].type == 1 ? 'left' : 'right'"
          src="../../assets/image/self/right.png"
          alt=""
        />
      </div>
      <ul class="btns">
        <li v-for="(item, index) in btnList" :key="index">
          <div :class="item.key == active ? 'keyBtn active' : 'keyBtn'">
            {{ item.key }}
          </div>
          <p :class="item.key == active ? 'name activeName' : 'name'">
            {{ item.name }}
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import Practice from '@/components/practice.vue';
import { saveSubjectiveAns } from '@/api/index';
//数据
let practiceData = [];
for (let index = 0; index < 4; index++) {
  practiceData.push({
    id: index + 1,
    correct_answer: index < 2 ? 'Q' : 'P',
    type: (index + 1) % 2 ? 1 : 2,
  }); //type位置 1左2右
}
let formalData = [];
for (let index = 0; index < 40; index++) {
  formalData.push({
    id: index + 1,
    correct_answer: index < 20 ? 'Q' : 'P',
    type: (index + 1) % 2 ? 1 : 2,
  }); //type位置 1左2右
}
export default {
  data() {
    return {
      isPractice: true, //是否练习题
      showStatus: true, //显示状态
      current: 0, //当前进度
      btnList: [
        { key: 'Q', name: '方向向左' },
        { key: 'P', name: '方向向右' },
      ],
      original: [], //用户原始数据
      startTime: 0, //起始时间
      endTime: 0, //结束时间
      startTimeTotal: 0, //起始总时间
      endTimeTotal: 0, //结束总时间
      timer: null, //计时器
      totalTime: 0, //用时
      active: '', //高亮显示
    };
  },
  components: {
    Practice,
  },
  watch: {},
  methods: {
    choose(keyName) {
      if (this.isPractice) {
        clearTimeout(this.timer);
        this.progressNext();
      } else {
        this.endTime = this.$util.getTimestamp();
        if (this.original[this.current].answer) { //已作答
          this.progressNext();
        } else {
          this.original[this.current].answer = keyName;
          this.original[this.current].time = this.endTime - this.startTime;
          if (keyName == this.original[this.current].correct_answer) {
            //是否正确
            this.original[this.current].correct_type = 1;
          } else {
            this.original[this.current].correct_type = 0;
          }
          clearTimeout(this.timer);
          this.progressNext();
        }
      }
    },
    //进行中
    progress() {
      this.startTime = this.$util.getTimestamp();
      this.timer = setTimeout(() => {
        if (!this.original[this.current].answer) {
          //用户未操作
          this.original[this.current].answer = null;
          this.original[this.current].time = 2000;
          this.original[this.current].correct_type = 0;
          this.progressNext();
        }
      }, 2000);
    },
    //进行中
    progressNext() {
      this.showStatus = false;
      if (this.current >= this.original.length - 1) {
        //完成
        this.finish();
      } else {
        setTimeout(() => {
          this.current++;
          this.active = '';
          this.showStatus = true;
          this.progress();
        }, 2000);
      }
    },
    //完成
    finish() {
      this.showStatus = false;
      this.endTimeTotal = this.$util.getTimestamp();
      this.totalTime = this.endTimeTotal - this.startTimeTotal;
      if (this.isPractice) {
        //练习题
        // if (this.totalTime > 18000 - 1000) {
          this.$router.replace({
            path: '/practiceEnd',
            query: {
              source: 'selfInterfer',
            },
          });
        // } else {
        //   this.$router.replace({
        //     path: '/relax',
        //     query: {
        //       time: 18000 - this.totalTime,
        //       path: '/practiceEnd?source=selfInterfer',
        //     },
        //   });
        // }
      } else {
        let query = {
          fmUserId: this.$util.getStorage('fmUserId'),
          fmProductQuestionId: '5',
          quesNum: '5-2',
          totalTime: this.totalTime,
          dataJason: JSON.stringify(this.original),
          remark:
            'id:序号；type:类型 1左侧 2右侧；correct_answer：正确答案；answer：用户答案；time：用时ms；correct_type:是否正确，1正确，0错误；',
        };
        // console.log(query);
        // console.log(this.original);
        saveSubjectiveAns(query)
          .then((res) => {
            if (res.code === 0) {
              //大题编号
              sessionStorage.setItem(
                'fmProductQuestionId',
                res.data.fmProductQuestionId
              );
              //小题编号
              sessionStorage.setItem('quesNum', res.data.quesNum);
              //是否完成
              sessionStorage.setItem('isFinished', res.data.isFinished);
              // if (this.totalTime > 180000 - 1000) {
                this.$router.replace({
                  path: '/complete',
                });
              // } else {
              //   this.$router.replace({
              //     path: '/relax',
              //     query: {
              //       time: 180000 - this.totalTime,
              //       path: '/complete',
              //     },
              //   });
              // }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  created() {
    if (this.$route.query.practice) {
      this.isPractice = false;
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer);
    document.onkeyup = '';
  },
  mounted() {
    this.original = [];
    if (this.isPractice) {
      this.original = this.$util.shuffle(practiceData);
    } else {
      this.original = this.$util.shuffle(formalData);
    }
    this.progress();
    this.startTimeTotal = this.$util.getTimestamp();
    document.onkeyup = (e) => {
      //答题状态时
      if (this.showStatus == true) {
        if (e.keyCode == 80) {
          this.choose('P');
          this.active = 'P';
        }
        if (e.keyCode == 81) {
          this.choose('Q');
          this.active = 'Q';
        }
      }
    };
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1900px) {
  .selfInterfer {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    font-family: 'SYHeiBold';
    .container {
      .main {
        width: 816px;
        height: 376px;
        background: #ffffff;
        border: 8px solid #f6f6f6;
        box-shadow: 0px 4px 16px rgba(100, 100, 100, 0.2);
        border-radius: 16px;
        margin: 0 auto;
        margin-top: 55px;
        position: relative;
        .line {
          // width: 285px;
          height: 505px;
          margin: 0 auto;
          position: absolute;
          left: 400px;
          top: -73px;
          border-left: 1px solid #5f9160;
          transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          // background: linear-gradient(
          //   to bottom right,
          //   transparent 0%,
          //   transparent calc(50% - 1px),
          //   #82B484 50%,
          //   transparent calc(50% + 1px),
          //   transparent 100%
          // );
        }
        img {
          width: 280px;
          margin: 80px 0;
        }
        .left {
          margin-left: -300px;
        }
        .right {
          margin-left: 300px;
        }
      }
      .btns {
        width: 330px;
        margin: 0 auto;
        overflow: hidden;
        margin-top: 55px;
        li {
          width: 100px;
          text-align: center;
          display: inline-block;
          .keyBtn {
            width: 70px;
            height: 70px;
            line-height: 70px;
            margin: 0 auto;
            text-align: center;
            background-image: url('../../assets/image/other/letter.png');
            background-repeat: no-repeat;
            background-size: cover;
            font-size: 34px;
            color: #ffffff;
          }
          .name {
            margin-top: 10px;
            color: #5f9160;
            font-size: 24px;
          }
          .active {
            background-image: url('../../assets/image/other/letter_check.png');
          }
          .activeName {
            color: #fa712d;
          }
        }
        li:first-child {
          float: left;
        }
        li:last-child {
          float: right;
        }
      }
    }
  }
}
@media screen and (min-width: 1900px) {
  .selfInterfer {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    font-family: 'SYHeiBold';
    .container {
      .main {
        width: 1061px;
        height: 489px;
        border: 10px solid #daecd4;
        box-shadow: 0px 4px 16px rgba(100, 100, 100, 0.2);
        border-radius: 20px;
        margin: 0 auto;
        margin-top: 72px;
        position: relative;
        .line {
          // width: 285px;
          height: 657px;
          margin: 0 auto;
          position: absolute;
          left: 520px;
          top: -95px;
          border-left: 1px solid #5f9160;
          transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          // background: linear-gradient(
          //   to bottom right,
          //   transparent 0%,
          //   transparent calc(50% - 1px),
          //   #82B484 50%,
          //   transparent calc(50% + 1px),
          //   transparent 100%
          // );
        }
        img {
          width: 364px;
          margin: 104px 0;
        }
        .left {
          margin-left: -390px;
        }
        .right {
          margin-left: 390px;
        }
      }
      .btns {
        width: 429px;
        margin: 0 auto;
        overflow: hidden;
        margin-top: 72px;
        li {
          width: 130px;
          text-align: center;
          display: inline-block;
          .keyBtn {
            width: 91px;
            height: 91px;
            line-height: 91px;
            margin: 0 auto;
            text-align: center;
            background-image: url('../../assets/image/other/letter.png');
            background-repeat: no-repeat;
            background-size: cover;
            font-size: 44px;
            color: #ffffff;
          }
          .name {
            margin-top: 13px;
            color: #5f9160;
            font-size: 31px;
          }
          .active {
            background-image: url('../../assets/image/other/letter_check.png');
          }
          .activeName {
            color: #fa712d;
          }
        }
        li:first-child {
          float: left;
        }
        li:last-child {
          float: right;
        }
      }
    }
  }
}
</style>
