<template>
  <!-- 练习题 -->
  <div class="practice">练习</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1900px) {
  .practice {
    width: 118px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-image: url('../assets/image/other/practice.png');
    background-repeat: no-repeat;
    background-size: cover;
    font-size: 20px;
    color: #fffef0;
    font-family: 'SYHeiBold';
  }
}
@media screen and (min-width: 1900px) {
  .practice {
    width: 153px;
    height: 52px;
    line-height: 52px;
    text-align: center;
    background-image: url('../assets/image/other/practice.png');
    background-repeat: no-repeat;
    background-size: cover;
    font-size: 26px;
    color: #fffef0;
    font-family: 'SYHeiBold';
  }
}
</style>
